import React from "react";
import "bootstrap/dist/js/bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Carousel } from "react-bootstrap";
import church from "../assets/church.jpg";
import picture1 from "../assets/picture1.jpg";



const Hero = () => (
<section name='home'>
  <div
    class="w-full bg-center bg-cover h-[15rem] md:h-[32rem] rounded"
    style={{backgroundImage: `url(${church})`}}
  >
    <div class="flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50">
      <div class="text-center">
        <h1 class=" text-4xl sm:text-8xl font-semibold text-gray-300 uppercase font-poppins pt-10">
          Living Hope Church <span class="text-blue-400 underline"></span>
        </h1>
        <p className="text-center text-[10px] sm:text-[15px] text-gray-50 font-poppins font-normal max-w-[600px] mx-auto">
        </p>
      </div>
    </div>
  </div>
  </section>
);

export default Hero;
