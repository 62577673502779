import React, { useState } from 'react'
import { Link } from 'react-scroll';
import {
    FaBars,
    FaTimes,
    FaHeart,
  } from 'react-icons/fa';

  // import { Link } from 'react-router-dom'








const Navbar = () => {
      const [nav, setNav] = useState(false);
      const handleClick = () => setNav(!nav);

  return (
  <div className=" pb-10">
   <div className='fixed w-full h-[80px] flex justify-between items-center px-4 bg-[#ffffff]  z-10 shadow-xl'>
    <div className='font-bold'>
        <h1 className='text-2xl font-poppins font-bold text-green-700'>LHC</h1>
    </div>

      {/* menu */}
      <ul className='hidden md:flex  font-poppins'>
        <li className='hover:text-green-700'>
          <Link to='home' smooth={true} duration={100}>
            Home
          </Link>
        </li>
        <li className='hover:text-green-700'>
          <Link to='about' smooth={true} duration={100}>
            About
          </Link>
        </li>
        <li className='hover:text-green-700'>
          <Link to='ministries' smooth={true} duration={100}>
            Ministries
          </Link>
        </li>
        <li className='hover:text-green-700'>
          <Link to='serve' smooth={true} duration={100}>
           Partner
          </Link>
        </li>
        <li className='hover:text-green-700'>
          <Link to='gallery' smooth={true} duration={100}>
            Gallery
          </Link>
        </li>
        <li className='hover:text-green-700'>
          <Link to='contact' smooth={true} duration={100}>
            Contact
          </Link>
        </li>
        <li className='flex justify-center border-2  border-black  rounded-[15px] font-semibold h-8 hover:text-green-700'>
            <a className='no-underline text-black'  href="https://www.paypal.com/mw/home" target="_blank">Donate</a>
        </li>
      </ul>

      {/* Hamburger */}
      <div onClick={handleClick} className='md:hidden z-10'>
        {!nav ? <FaBars /> : <FaTimes />}
      </div>

      {/* Mobile menu */}
      <ul
        className={
          !nav
            ? 'hidden'
            : 'absolute top-0 left-0 w-full h-screen bg-[#ffffff] flex flex-col justify-center items-center'
        }
      >
        <li className='py-6 text-2xl'>
          <Link onClick={handleClick} to='home' smooth={true} duration={500}>
            Home
          </Link>
        </li>
        <li className='py-6 text-2xl'>
          {' '}
          <Link onClick={handleClick} to='about' smooth={true} duration={500}>
            About
          </Link>
        </li>
        <li className='py-6 text-2xl'>
          {' '}
          <Link onClick={handleClick} to='ministries' smooth={true} duration={500}>
            Ministries
          </Link>
        </li>
        <li className='py-6 text-2xl'>
          {' '}
          <Link onClick={handleClick} to='serve' smooth={true} duration={500}>
            Partner
          </Link>
        </li>
        <li className='py-6 text-2xl'>
          {' '}
          <Link onClick={handleClick} to='gallery' smooth={true} duration={500}>
            Gallery
          </Link>
        </li>
        <li className='py-6 text-2xl'>
          {' '}
          <Link onClick={handleClick} to='contact' smooth={true} duration={500}>
            Contact 
          </Link>
        </li>
        <li className='py-6 text-2xl border-black rounded-[15px] border-2'>
          {' '}
          <Link onClick={handleClick} to='donate' smooth={true} duration={500}>
            Donate 
          </Link>
        </li>
      </ul>

   </div>
   </div>
   
  )
}

export default Navbar