import React from "react";
import { ministriesData } from "../data";

const Ministries = () => (
    // <div name="ministries" className="w-full  bg-[#0a192f] pt-10">
    //   {ministriesData.map((item, index) => (
    //     <div class="flex justify-center   pt-14">
    //       <div
    //         key={index}
    //         class="max-w-2xl bg-[#dadada] rounded-lg border border-gray-200 shadow-md"
    //       >
    //         <img
    //           class="rounded-t-lg w-full max-h-[400px] object-cover"
    //           src={item.image}
    //           alt="bible distribution"
    //         />

    //         <div class="p-5">
    //           <h5 class="mb-2 text-2xl font-bold tracking-tight text-center text-black font-open">
    //             {item.title}
    //           </h5>

    //           <p class="mb-3 font-normal text-center text-black font-nunito">
    //             {item.description}
    //           </p>

    //           <div class="mt-3">
    //             <p class="text-[10px] font-bold text-black">
    //               {/* Posted: 2 minutes ago */}
    //             </p>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   ))}
    // </div>
<section name="ministries" className="text-gray-600 body-font">
  <div className="container py-24 mx-auto">
    <div className="flex flex-wrap w-full mb-20">
      <div className="lg:w-1/2 w-full mb-6 lg:mb-0">
        <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900 font-poppins">Our Ministries</h1>
        <div className="h-1 w-20 bg-green-700 rounded"></div>
      </div>
      <p className="lg:w-1/2 w-full leading-relaxed text-gray-500 font-poppins">here are some of the ministries that we've been doing across the country.</p>
    </div>
    
    <div className="flex flex-wrap -m-4">
    {ministriesData.map((item, index) => (
      <div key={index} className="xl:w-1/3 sm:w-1/3 p-4">
        <div className="bg-gray-100 p-6 rounded-lg shadow-md focus:shadow-2xl">
          <img className="h-40 rounded w-full object-cover object-center mb-6" src={item.image} alt="content"/>
          <h2 className="text-lg text-green-700 font-medium title-font mb-4 font-poppins text-center">{item.title}</h2>
          <p className="leading-relaxed text-base font-poppins text-center text-[13px] md:text-[15px]">{item.description}</p>
        </div>
      </div>
      ))}
    </div>
  </div>
</section>
);


export default Ministries;
