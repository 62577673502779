import React from 'react'
import { MissionData } from '../data'


const Mission = () =>  (
<section name="about" class="text-black body-font" data-aos="fade-down">
  <div  class="py-6 mx-auto max-w-[1200px] bg-[white]" >
    <div class="flex flex-col md:flex-row m-4 justify-center">
      {MissionData.map((item, index) => (
      <div key={index} class="max-w-[600px] max-h-[350px] bg-gray-100 m-2 rounded-md shadow-md px-4">
          <h1 class="title-font sm:text-2xl text-xl font-medium text-green-700 mb-3 font-poppins text-center border-b-2">{item.title}</h1>
          <p class="leading-relaxed mb-3 font-poppins text-center text-[13px] md:text-[15px]">{item.description}</p>
        </div>
      ))}
    </div>
  </div>
</section>
)


export default Mission