import React,{useEffect} from 'react';
import "./App.css";
import Contact from "./components/Contact";
import Gallery from "./components/Gallery";
import Hero from "./components/Hero";
import Ministries from "./components/Ministries";
import Mission from "./components/Mission";
import Navbar from "./components/Navbar";
import About from "./components/About";
import AOS from "aos";
import "aos/dist/aos.css";
import Serve from './components/Serve';


function App() {
  useEffect(() => {
    AOS.init({
      duration : 1000
    });
  }, []);

  return (
   
    <div className="">
    
      <Navbar />
      
        <Hero/>
        <About/>
        <Mission/>
        <Gallery/>
        <Ministries/>
        <Serve />
        <Contact />
      
     
      
      {/* <Contact /> */}
     
    </div>
    
   
  );
}

export default App;
