import React from "react";

const About = () => {
  return (
    <div
      name="about"
      className="w-full h-[350px] bg-[#fff] text-gray-50 p-4"
    >
      <div className="max-w-[950px] mx-auto h-[20rem] bg-[#ffffff] leading text-gray-gray rounded-[15px]">
        <h3 className="text-center border-b-2 pb-2 font-poppins text-green-700">
          Who We Are?
        </h3>
        <p className="text-center text-[13px] md:text-[15px] m-2 font-poppins text-black pt-2">
          Living Hope Church was founded by Bishop Hussein Nguwo  in the year 2003 in the City of Blantyre in the southern part of Malawi,  from a family gathering and a few members to a large congregation with  a lot of branches across the country,
          later on in the year 2005 the church  headquarters was moved in Chikwawa where we have been doing a lot ministries, Living hope church is affiliated to Jesus Way School of Evangelism,which is a school that equips and trains  interdenominational  members on how to reach many souls with the gospel of our Lord Jesus Christ through evangelism.
        </p>
      </div>
    </div>
  );
};

export default About;
