
// Images for ministries 
import PBS from "./assets/picture1.jpg";
import bible from "./assets/bible.jpg";
import children from "./assets/children.jpeg";
import evangelism from "./assets/evangelism.jpg";

// Images for Gallery
import leaders from './assets/leaders.jpg';
import evangelism1 from "./assets/evangelism.jpg";
import graduation from "./assets/graduation.jpg";
import outreach from "./assets/outreach.jpg";
import grad from "./assets/grad.jpg";
import bible1 from "./assets/mawu.jpeg";
import bishop from "./assets/bishop.jpeg";
import feeding from "./assets/feeding_elderly.jpg";
import Gallery from "./components/Gallery";





// Mission Data
export const MissionData = [
  {
    title: 'Mission',
    description: 'To become an equipping and mobilizing church that transforms our world for Jesus Christ and train new converts stay rooted in faith.',
  },

  {
    title: 'Beliefs',
    description: 'Our God is one but manifested in three persons, Father , Son and Holy Spirit, Bible as the inspired word of God, we believe in justification by faith,We believe that a man is a fallen object that needs God’s Grace to be saved, we believe in Holiness, We believe in a church as the body of Christ whose head is Jesus Christ.',
  },

  {
    title: 'Vision',
    description: 'As an evangelistic church we would love to see many souls saved and transformed with the gospel of our Lord Jesus Christ.',
  },
];

export const ministriesData = [
    {
        title: 'Portable Bible School',
        image: PBS,
        description: 'This education, offered for free to all who make the commitment to put the whole Word of God into practice, covers the essential theological training anyone would need to serve as a pastor, teacher, or other function of the body of Christ in their sphere of influence.'

    },
    
    {
        title: 'Bible Distribution',
        image: bible1,
        description: 'With the majority of the populations we minister to, the Word is the essential core of our foundations, so we distribute bibles for free  to villages, It is the strength and power of God’s Word that is bringing national transformation, village by village and tribe by tribe. '

    },

    {
        title: 'Evangelism Training',
        image: grad,
        description: 'Living Hope Church  is dedicated to winning the lost to Jesus Christ. Since 2006 together with Jesus Way School of Evangelism,we have has been equipping pastors and church leaders from Malawi, Zambia and Mozambique to give a clear, understandable, Biblical presentation of the Gospel message of Jesus Christ, effectively and boldly.  '

    },

    {
      title: 'Children Ministry',
      image: children,
      description: 'The goal of the Children’s ministry department is to nurture children into a loving, serving relationship with Jesus.'
  },

]

export const  galleryData = [
      {
        id: 1,
        image: leaders,

      },

      {
        id: 2,
        image: evangelism1,

      },

      {
        id: 3,
        image: bible1,

      },

      {
        id: 4,
        image: bishop,

      },

      {
        id: 5,
        image: feeding,
      },

      {
        id: 6,
        image: outreach,
      },
    
]

