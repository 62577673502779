import React from 'react'
import { galleryData } from '../data';

const Gallery = () => (
  
<section name="gallery" class="text-gray-600 body-font" data-aos="fade-down">
  <div class="container  py-24 mx-auto">
    <div class="flex flex-col text-center w-full mb-20">
      <h1 class="sm:text-3xl text-2xl font-medium title-font mb-4 text-green-700 font-poppins">Our Gallery</h1>
      <p class="lg:w-2/3 mx-auto leading-relaxed text-base font-poppins">Here are some of our ministry pictures</p>
    </div>
    <div class="flex flex-wrap -m-4 ">
      {galleryData.map((item, id) =>(

    
      <div key={id} class="lg:w-1/3 sm:w-1/3 p-4">
        <div class="flex relative shadow-lg">
          <img alt="gallery" class="absolute inset-0 w-full h-full object-cover object-center rounded" src={item.image} />
          <div class="px-8 py-10 relative z-10 w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100">
            <h2 class="tracking-widest text-sm title-font font-medium text-green-500 mb-1">THE SUBTITLE</h2>
            <h1 class="title-font text-lg font-medium text-gray-900 mb-3 font-poppins">Alper Kamu.</h1>
            <p class="leading-relaxed font-poppins">Photo booth fam kinfolk cold-pressed sriracha leggings jianbing microdosing tousled waistcoat.</p>
          </div>
        </div>
      </div>
      ))}
    </div>
    
  </div>
</section>

  )



export default Gallery;