import React from 'react'

const Contact = () => (
  <section name="contact" className="text-gray-600 body-font relative">
  <div className="absolute inset-0 bg-gray-300">
    {/* <iframe width="100%" height="100%" frameborder="0" marginheight="0" marginwidth="0" title="map" scrolling="no" src="https://maps.google.com/maps?width=100%&amp;height=600&amp;hl=en&amp;q=%C4%B0zmir+(My%20Business%20Name)&amp;ie=UTF8&amp;t=&amp;z=14&amp;iwloc=B&amp;output=embed" Style={"filter:  contrast(1.2) opacity(0.4);"}></iframe> */}
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3834.4171137638864!2d34.799489214736205!3d-16.043830533413292!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x18d8169155555555%3A0x28c87472ba313728!2sDyeratu%20Trading%20Center!5e0!3m2!1sen!2smw!4v1662580440253!5m2!1sen!2smw" width="100%" height="100%" Style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
  </div>
  <div className=" container px-7 py-24 mx-auto flex]">
    <div className="  w-full bg-gray-100 rounded-lg p-4 flex flex-col md:ml-auto  mt-10 md:mt-0 relative z-9 shadow-md mx-auto">
      <h2 className="text-gray-900 text-lg mb-1 font-medium title-font">Contact Us</h2>
      
      <form method='POST' action='https://getform.io/f/1a6c69ff-766f-48a2-aad0-7070abce7b0f'>
      <div className="relative mb-4">
        <label for="name" className="leading-7 text-sm text-gray-600">Name</label>
        <input type="text" id="name" name="name" className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
      </div>
      <div className="relative mb-4">
        <label for="email" className="leading-7 text-sm text-gray-600">Email</label>
        <input type="email" id="email" name="email" className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
      </div>
      <div className="relative mb-4">
        <label for="message" className="leading-7 text-sm text-gray-600">Message</label>
        <textarea id="message" name="message" className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
      </div>
      <button className="text-white bg-green-700 border-0 py-2 px-6 focus:outline-none hover:bg-green-900 rounded text-lg">Send</button>
      </form>
      <p className="text-xs text-gray-500 mt-3">we will get back to you shortly.</p>
    </div>
  </div>
</section>
 )


export default Contact