import React from 'react'

const Serve = () => (
   <div name='serve' className='sm:pt-2 md:pt-24 container'>
      <div className="max-w-[950px] mx-auto h-[20rem] bg-[#ffffff] leading text-gray-gray rounded-[15px]">
        <h3 className="text-center font-bold border-b-2 pb-2 font-poppins text-green-700">
          A Call For Missionaries
        </h3>
        <p className="text-center text-[15px] m-2 font-poppins text-black pt-2">
          Are you feeling called to serve as a Missionary at our Church? Please join us in missionary work as we engage the unreached, make disciples and establish reproducing churches.
        </p>
      </div>
   </div>
  )


export default Serve;